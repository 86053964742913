import React, { Component } from "react";
import { MDBRow, MDBCol, MDBBtn, MDBIcon } from 'mdbreact';
import { postBanner, updateCompanyApi, postCompany, getCompany, updateAboutApi, showAllAboutByCompanyId } from '../../../../Api/api';
import SpinnerPage from '../../../pages/SpinnerPage';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import "./index.scss";
import {successToast, errorToast} from '../../../../utils/toast'
import { MDBContainer } from "mdbreact";

class AboutIntroductionPage extends Component<any, any> {

  constructor(props: any) {
    super(props)

    
    this.state = {
      company: {
        id: '',
        name: "",
        description: '',
        banner_caption: '',
        baImage: '',
        image: undefined,
        category: '',
        categories: []
      },
      images: [],
      loading: true,
      editorState: EditorState.createEmpty(),
    };
   
  }

  componentDidMount() {
    this.setState({loading: true});
    showAllAboutByCompanyId(this.props.companyId.companyId)
        .then((res: any) => {
          this.changeState(res.abouts[0].description)
          this.setState({loading: false});
        })
        .catch((err: any) => {
          alert('Error to get About ' + err)
        });
  }

  changeState(value: any){
    if(value.replace(/(<([^>]+)>)/ig,"").trim() != ''){
      const contentBlock = convertFromHTML(value);
      if (contentBlock) {
      
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks, contentBlock.entityMap);
          const editorState = EditorState.createWithContent(contentState);
          this.onEditorStateChange(editorState);
      }
    }
  }

  onEditorStateChange: any = (editorState: EditorState) => {
    this.setState({
      editorState,
    });
  };
  updateAbout = (event: any) => {

    event.preventDefault();
    const aboutId = this.props.companyId.company.abouts[0].id;
    const companyId = this.props.companyId.company.abouts[0].company_id;
    const src = this.props.companyId.company.abouts[0].src;
    const companyDescription = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
    const form = new FormData();
    form.append("company_id", companyId);
    form.append("description", companyDescription);
    form.append("src", src);
    updateAboutApi(aboutId, form).then(res => {
      successToast('About updated Sucessfully');
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      
    });


  }

  

  render() {
    return (
      this.state.loading ? <SpinnerPage /> :
        <MDBRow>
          <MDBCol >
            <MDBContainer className="content_label">
                  <MDBRow>
                      <MDBCol lg="12" >
                          Content(s)
                      </MDBCol>
                  </MDBRow>
            </MDBContainer>
            <MDBContainer className="about_intro_editor">
                <Editor
                editorState={this.state.editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="form-control"
                onEditorStateChange={this.onEditorStateChange}
              />
            </MDBContainer>
              <MDBContainer className="text-left mt-4">
                <MDBBtn color="primary" outline type="submit" className='w-10' onClick={this.updateAbout}>
                  Save
                <MDBIcon far icon="save" className="ml-2" />
                </MDBBtn>
              </MDBContainer>
              <br />

          </MDBCol>
        </MDBRow>
    );
  };
}


export default AboutIntroductionPage;


import React, { Fragment, useState, Component } from "react";
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn, MDBIcon, MDBContainer, MDBRow, MDBCol, MDBInput } from "mdbreact";
import { postHomePageApi, updateHomePageApi } from "../../../../Api/homePageApi";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import LightTooltip from "../../../tooltip";
import { successToast, errorToast } from "../../../../utils/toast";
import { config } from "../../../../config/common";

import { FormControl, Form } from 'react-bootstrap';


import { Tabs, Tab } from 'react-bootstrap';


export default class HomeModal extends Component<any, any>{

    constructor(props: any) {
        super(props)
        this.state = {
            title: "",
            description: "",
            image_url: "",
            video_url: "",
            rowData: this.props.rowData,
        };
        this.handleInput = this.handleInput.bind(this);
        this.addHomePage = this.addHomePage.bind(this);
        this.updateHomePage = this.updateHomePage.bind(this);

        this.createImage = this.createImage.bind(this);
        this.onDropFiles = this.onDropFiles.bind(this);

        this.checkHomePageForm = this.checkHomePageForm.bind(this);
    }

    handleInput = (e: any) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    createImage(file: File) {
        let reader = new FileReader();

        reader.onload = (e) => {
            this.setState({
                baImage: reader.result as string,
                image: file
            })
        };
        reader.readAsBinaryString(file);
    }

    onDropFiles(files: File[]) {
        if (!files.length)
            return;

        this.createImage(files[0]);
    }

    checkHomePageForm = () => {
        const title = this.state.title == "" && this.props.rowData  ? this.props.rowData.title : this.state.title;
        if(title === "")
            return "Project Title should not be empty"
        return null;
    }

    updateHomePage = (company_id: any) => {
        const message = this.checkHomePageForm();
        if(message != null){
            return alert(message);
        }
        const title = this.state.title == "" && this.props.rowData ? this.props.rowData.title : this.state.title;
        const description = this.state.description == "" && this.props.rowData  ? this.props.rowData.description : this.state.description;

        const video_url = this.state.video_url == ""
            ? this.props.rowData ? (this.props.rowData.video_url) : ""
            : (this.state.video_url);
        const image_url = this.state.image_url == ""
            ? this.props.rowData ? (this.props.rowData.image_url) : ""
            : (this.state.image_url);

        const form = new FormData();
        form.append("id", this.props.rowData.id);
        form.append("title", title);
        form.append("company_id", company_id);
        form.append("description", description);
        form.append("video_url", video_url != null ? video_url : '');
        form.append("image_url", image_url != null ? image_url : '');
        form.append("image", this.state.image);

        updateHomePageApi(this.props.rowData.id, form)
            .then((res: any) => {
                successToast('Company Home Page Updated Sucessfully');
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            })
            .catch((err: any) => {
                errorToast('Company Home Page Update On Error');
            });
    }

    addHomePage = (company_id: any) => {
        const message = this.checkHomePageForm();
        if(message != null){
            return alert(message);
        }

        const title = this.state.title == "" && this.props.rowData ? this.props.rowData.title : this.state.title;
        const description = this.state.description == "" && this.props.rowData ? this.props.rowData.description : this.state.description;

        const video_url = this.state.video_url == ""
            ? this.props.rowData && this.props.rowData.video_url != "" ? (this.props.rowData.video_url) : ""
            : (this.state.video_url);
        const image_url = this.state.image_url == ""
            ? this.props.rowData && this.props.rowData.image_url != "" ? (this.props.rowData.image_url) : ""
            : (this.state.image_url);
        const form = new FormData();
        form.append("title", title);
        form.append("company_id", company_id);
        form.append("description", description);
        form.append("video_url", video_url != null ? video_url : '');
        form.append("image_url", image_url != null ? image_url : '');
        form.append("image", this.state.image);

        postHomePageApi(form)
            .then((res: any) => {
                successToast('Company Home Page Created Sucessfully');
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            })
            .catch((err: any) => {
                errorToast('Company Home Page Create On Error');
            });
    }


    render() {
        const openStatus = this.props.open;
        const toggle = this.props.toogle;
        const rowData = this.props.rowData;
        const company = this.props.company;
        return (
            <Fragment>
                <br />

                <MDBModal isOpen={openStatus} toggle={toggle} size="lg">
                    <MDBModalHeader toggle={toggle}>Add New Home Page </MDBModalHeader>
                    <MDBModalBody className="needs-validation" noValidate >
                        <MDBContainer className="home_company_name">
                            <MDBRow>
                                <MDBCol md="12" >
                                    <MDBInput
                                        size="sm"
                                        icon="building"
                                        outline
                                        disabled
                                        name="company_name"
                                        valueDefault={company && company.name}
                                        onInput={this.handleInput}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>

                        <MDBContainer className="home_project_title">
                            <MDBRow>
                                <MDBCol md="12" className="url">
                                    <MDBInput
                                        size="sm"
                                        icon="map-marker-alt"
                                        label="Project Title"
                                        required
                                        outline
                                        name="title"
                                        onInput={this.handleInput}
                                        valueDefault={rowData && rowData.title}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>

                        <MDBContainer className="home_project_description">
                            <MDBRow>
                                <MDBCol md="12" className="url">
                                    <MDBInput
                                        size="sm"
                                        type="textarea"
                                        icon="map-marker-alt"
                                        label="Project Description"
                                        outline
                                        name="description"
                                        onInput={this.handleInput}
                                        valueDefault={rowData && rowData.description}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>

                        <MDBContainer className="home_image_video">
                            {/* <MDBRow>
                                <MDBCol md="6" className="image">
                                    <MDBInput 
                                        size="sm"
                                        icon="location-arrow" 
                                        label="Image Url" 
                                        outline 
                                        disabled={this.state.video_url != ""}
                                        name="image_url"
                                        valueDefault= {rowData && rowData.image_url}
                                        onInput={this.handleInput}
                                        />
                                </MDBCol>
                                <MDBCol md="6" className="video">
                                    <MDBInput 
                                        size="sm" 
                                        icon="globe" 
                                        label="Video Url" 
                                        type=""
                                        outline 
                                        disabled={this.state.image_url != ""}
                                        name="video_url"
                                        onInput={this.handleInput}
                                        valueDefault= {rowData && rowData.video_url}
                                        />
    
                                </MDBCol>
    
                            </MDBRow> */}

                            <Tabs defaultActiveKey="image_url" id="image_video_url">
                                <Tab eventKey="image_url" title="Image Url">
                                    <MDBContainer className="image_url">
                                        <MDBRow>
                                            <MDBCol md="12" className="image_url">
                                                {
                                                    !this.state.image ?
                                                        <FormControl type="file" onChange={(e: any) => this.onDropFiles(e.target.files)} />
                                                        : this.state.image != undefined ? <img src={URL.createObjectURL(this.state.image)} alt="thumbnail" className="img-thumbnail" style={{ width: '500px' }}></img> : null

                                                }
                                                {
                                                    rowData != undefined && rowData.image_url != "" &&
                                                    // <div>
                                                    //     {/* <FormControl type="file" onChange={(e: any) => this.onDropFiles(e.target.files)}/> */}
                                                    //     <img src={config().rest_url + '/' + rowData.image_url} alt="thumbnail" className="img-thumbnail" style={{ width: '500px' }}></img>
                                                    // </div>
                                                    <span>
                                                       {rowData.image_url}
                                                       <div onClick={ () => alert("clicked")} style={{color: "red", padding:"2px", cursor:"pointer"}}>
                                                           remove
                                                       </div>
                                                    </span>

                                                }
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBContainer>
                                </Tab>
                                <Tab eventKey="video_url" title="Video Url">
                                    <MDBContainer className="video_url">
                                        <MDBRow>
                                            <MDBCol md="12" className="video_url">
                                                <MDBInput
                                                    size="sm"
                                                    label="Video Url"
                                                    outline
                                                    name="video_url"
                                                    onInput={this.handleInput}
                                                    valueDefault={rowData && rowData.video_url}
                                                />
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBContainer>
                                </Tab>
                            </Tabs>

                        </MDBContainer>

                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="danger" onClick={() => { toggle(); }}>Close</MDBBtn>
                        {this.props.edit ?

                            <MDBBtn
                                color="primary"
                                outline
                                type="submit"
                                className='w-30'
                                onClick={() => { toggle(); this.updateHomePage(company.id) }}
                            >
                                Update
                                <MDBIcon far icon="plus-square" className="ml-2" />
                            </MDBBtn> :
                            <MDBBtn
                                color="primary"
                                outline
                                type="submit"
                                className='w-30'
                                onClick={() => { toggle(); this.addHomePage(company.id) }}
                            >
                                Add
                    <MDBIcon far icon="plus-square" className="ml-2" />
                            </MDBBtn>
                        }
                    </MDBModalFooter>
                </MDBModal>

            </Fragment>
        )
    }

}

import React, { Fragment, useState, Component } from "react";
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn, MDBIcon, MDBContainer, MDBRow, MDBCol, MDBInput} from "mdbreact";
import { postWorkCategoryApi, updateWorkCategoryApi} from "../../../../../../Api/workCategory";
import { successToast, errorToast } from "../../../../../../utils/toast";
import CompanyContext from "../../../../homepage/context/companyContext";
import Select from 'react-select';
import { postWorkApi, updateWorkApi } from "../../../../../../Api/work";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export default class WorkModal extends Component<any, any>{

    static contextType = CompanyContext;

    constructor(props: any) {
        super(props)
        this.state = {
            title: "",
            description: "",
            category_id: null,
            rowData: this.props.rowData,
            editorState: EditorState.createEmpty(),
        };
        this.handleInput = this.handleInput.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.addWork = this.addWork.bind(this);
        this.updataWork = this.updataWork.bind(this);
    }
    
    componentDidMount() {
        const { rowData } = this.props;
        if (rowData !== undefined && rowData.description) {
        if(this.props.rowData.description.replace(/(<([^>]+)>)/ig,"").trim() != ''){
            const contentBlock = convertFromHTML(this.props.rowData.description);
            if (contentBlock) {
            
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks, contentBlock.entityMap);
                const editorState = EditorState.createWithContent(contentState);
                this.onEditorStateChange(editorState);
            }
        }
        }
    }

      onEditorStateChange: any = (editorState: EditorState) => {
        this.setState({
          editorState,
        });
      };

    handleInput = (e: any) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleChange = (selectedOption: any) => {
        this.setState({ 
            category_id: selectedOption.value
         });
      };

    updataWork = () => {
        let value = this.context;
        const title = this.state.title == "" ? this.props.rowData.title : this.state.title;
        const description = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
        const category_id = this.state.category_id == null ? this.props.rowData.category_id : this.state.category_id;
        const form = new FormData();
        form.append("id", this.props.rowData.id);
        form.append("title", title);
        form.append("company_id", value);
        form.append("category_id", category_id);
        form.append("description", description);

        updateWorkApi(this.props.rowData.id, form)
            .then((res: any) => {

                successToast('Company Work Updated Sucessfully');
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            })
            .catch((err: any) => {
                errorToast('Company Work Update On Error');
            });
    }

    addWork = () => {
        let value = this.context;
        const title = this.state.title == "" ? this.props.rowData.title : this.state.title;
        const description = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
        const category_id = this.state.category_id == null ? this.props.rowData.category_id : this.state.category_id;
        const form = new FormData();
        form.append("title", title);
        form.append("company_id", value);
        form.append("category_id", category_id);
        form.append("description", description);
        postWorkApi(form)
            .then((res: any) => {
                successToast('Company Work Created Sucessfully');
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            })
            .catch((err: any) => {
                errorToast('Company Work Create On Error');
            });
    }


    render() {
        const openStatus = this.props.open;
        const toggle = this.props.toogle;
        const rowData = this.props.rowData;
        const workCategories = this.props.workCategories;
        return (
            <Fragment>
                <br />

                <MDBModal isOpen={openStatus} toggle={toggle} size="lg" >
                    <MDBModalHeader toggle={toggle}>Add New Work Category </MDBModalHeader>
                    <MDBModalBody>
                        <MDBContainer className="work_project_title">
                            <MDBRow>
                                <MDBCol md="12" >
                                    <MDBInput
                                        size="sm"
                                        outline
                                        label="Project Title"
                                        name="title"
                                        valueDefault={rowData && rowData.title}
                                        onInput={this.handleInput}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>

                        <MDBContainer className="work_category_select">
                            <MDBRow>
                                <MDBCol md="12" >
                                    <Select 
                                        options={workCategories}
                                        name="category_id"
                                        placeholder="Select Work Category"    
                                        // defaultValue={workCategories[0]}
                                        defaultValue={rowData && workCategories[workCategories.findIndex((x:any) => x.value === rowData.category_id)]}
                                        onChange={this.handleChange}
                                        styles={{
                                            menu: (provided, state) => ({
                                                ...provided,
                                                zIndex: 1000,
                                            }),
                                        }}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        <br/>
                        <MDBContainer className="work_project_description">
                            <MDBRow>
                                <MDBCol md="12" className="url">
                                    {/* <MDBInput
                                        size="sm"
                                        type="textarea"
                                        label="Project Description"
                                        outline
                                        name="description"
                                        onInput={this.handleInput}
                                        valueDefault={rowData && rowData.description}
                                    /> */}
                                    <Editor
                                        editorState={this.state.editorState}
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="form-control"
                                        onEditorStateChange={this.onEditorStateChange}
                                        editorStyle={{ height: '150px' }}
                                        toolbar={{
                                            options: ['inline', 'blockType', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                                            fontFamily: {
                                              options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                                            }
                                          }}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="danger" onClick={() => { toggle(); }}>Close</MDBBtn>
                        {this.props.edit ?

                            <MDBBtn
                                color="primary"
                                outline
                                type="submit"
                                className='w-30'
                                onClick={() => { toggle(); this.updataWork() }}
                            >
                                Update
                                <MDBIcon far icon="plus-square" className="ml-2" />
                            </MDBBtn> :
                            <MDBBtn
                                color="primary"
                                outline
                                type="submit"
                                className='w-30'
                                onClick={() => { toggle(); this.addWork() }}
                            >
                                Add
                    <MDBIcon far icon="plus-square" className="ml-2" />
                            </MDBBtn>
                        }
                    </MDBModalFooter>
                </MDBModal>

            </Fragment>
        )
    }

}
